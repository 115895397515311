// External Dependencies
import { useQuery } from '@tanstack/react-query';

// Internal Dependencies
import { apiClient } from 'utils/apiClient';

// Hook Definitions
export const useGetInventoryItemRepairList = (inventoryItemId: string) => {
  return useQuery({
    enabled: Boolean(inventoryItemId),
    queryFn: () => {
      return apiClient.v1.inventoryRepair.list({
        params: { inventoryItemId },
      });
    },
    queryKey: [inventoryItemId, 'inventoryItemRepairList'],
  });
};
