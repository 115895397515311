// External Dependencies
import {
  useCallback, useEffect, useState,
} from 'react';
import DisableIcon from '@mui/icons-material/RemoveCircleOutline';
import EnableIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import {
  ConfirmationDialog,
  EnhancedAlert,
  ListItemWithSecondaryAction,
  StyledLink,
} from 'components/shared';
import { classLinkUrl } from 'utils/constants/urls';
import { useIsOpen } from 'hooks/useIsOpen';
import { useMyDistrict, useUpdateSsoTenantId } from 'utils/api/district';
import { useParseToken } from 'utils/cookies';

// Component Definition
const ClassLinkSsoSetting = (): JSX.Element | null => {
  const [shouldPresentEnabledInDialog, setShouldPresentEnabledInDialog] = useState(false);

  const {
    data: districtData,
    isLoading: isDistrictLoading,
  } = useMyDistrict();

  const {
    isOpen: isDialogOpen,
    toggleIsOpen: toggleIsDialogOpen,
  } = useIsOpen();

  const parsedToken = useParseToken();

  const ssoTenantId = districtData?.data.data.ssoTenantId;

  const {
    isLoading: isUpdatingSsoTenantId,
    mutate: updateSsoTenantId,
  } = useUpdateSsoTenantId();

  const handleSubmit = useCallback(() => {
    updateSsoTenantId({
      isEnabled: !ssoTenantId,
    }, {
      onSuccess: toggleIsDialogOpen,
    });
  }, [toggleIsDialogOpen, ssoTenantId, updateSsoTenantId]);

  useEffect(() => {
    if (isDialogOpen) {
      setShouldPresentEnabledInDialog(Boolean(ssoTenantId));
    }
    // intentionally leaving out ssoTenantId from this dependency array
    // this allows the dialog content to persist while closing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen]);

  if (isDistrictLoading) {
    return null;
  }

  return (
    <>
      <ListItemWithSecondaryAction
        primaryText={<>ClassLink SSO Required: <strong>{ssoTenantId ? 'ON' : 'OFF'}</strong></>}
        secondaryAction={{
          alwaysShowButton: true,
          buttonIcon: ssoTenantId ? <DisableIcon /> : <EnableIcon />,
          buttonText: ssoTenantId ? 'Disable' : 'Enable',
          isLoading: isUpdatingSsoTenantId,
          onClick: toggleIsDialogOpen,
        }}
        secondaryText={(
          <>
            Require teachers and administrators in your district to sign in using
            {' '}
            <StyledLink
              href={classLinkUrl}
              openInNewTab
            >
              ClassLink
            </StyledLink>{' '}
            .
          </>
        )}
      />

      <ConfirmationDialog
        confirmButtonAction={handleSubmit}
        confirmButtonText={shouldPresentEnabledInDialog ? 'Yes, disable' : 'Yes, enable'}
        declineButtonAction={toggleIsDialogOpen}
        description={shouldPresentEnabledInDialog
          ? 'This will allow all directors and administrators in your district to sign in using an email and password.'
          : (
            <>
              <Typography
                color="textSecondary"
                gutterBottom
              >
                This will require all directors and administrators in your district to
                sign in using ClassLink instead of entering an email and password.
              </Typography>

              {!parsedToken?.ssoTenantId && (
                <EnhancedAlert
                  severity="warning"
                  sx={{
                    marginTop: 2,
                  }}
                >
                  You will need to sign out and sign back
                  in using ClassLink SSO to enable this feature.
                </EnhancedAlert>
              )}
            </>
          )}
        handleClose={toggleIsDialogOpen}
        isConfirmButtonDisabled={!parsedToken?.ssoTenantId}
        isSubmitting={isUpdatingSsoTenantId}
        open={isDialogOpen}
        title={shouldPresentEnabledInDialog ? 'Disable ClassLink SSO?' : 'Enable ClassLink SSO?'}
      />
    </>
  );
};

export default ClassLinkSsoSetting;
